import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { AppState } from ".";
import { getFreshAccessToken } from "./auth";

export interface FilesQueryValues {
  prompt: string;
  files?: File[];
}

export interface Sources {
  [key: string]: {
    content: string;
    filename: string;
  };
}

export interface ResponseChunk {
  text: string;
  reference?: string;
}

export interface FilesQueryState {
  parsedText: ResponseChunk[];
  sources: Sources;
  isLoading: boolean;
  isError: boolean;
}

const initialState: FilesQueryState = {
  parsedText: [],
  sources: {},
  isLoading: false,
  isError: false,
};

export const name = "filesQuery";

function parseTextWithReferences(text: string) {
  const regex = /\[(\d+)\]/g;
  const matches = text.match(regex) || [];
  const chunks: ResponseChunk[] = [];
  let startIndex = 0;
  for (let i = 0; i < matches.length; ++i) {
    const refIndex = text.indexOf(matches[i]);
    chunks.push({
      text: text.slice(startIndex, refIndex),
      reference: matches[i].slice(1, matches[i].length - 1),
    });
    startIndex = refIndex + matches[i].length;
  }
  chunks.push({ text: text.slice(startIndex) });
  return chunks;
}

export const submitFilesQuery = createAsyncThunk<any, FilesQueryValues, {state: AppState}> (
  `${name}/submit`,
  async (values, thunkApi) => {
    const formData = new FormData();
    if (values.files && values.files.length > 0) {
      values.files.forEach((file) => {
        formData.append("files", file);
      });
    }    
    const { payload : { accessToken } } = await thunkApi.dispatch(getFreshAccessToken());
    const bearer = accessToken;
    formData.append("query", values.prompt);
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    };
    const responseData = await axios.post("/api/ask", formData, config);
    const resultString: string = responseData.data.result;
    const parsedText = parseTextWithReferences(resultString);

    return { parsedText, sources: responseData.data.sources };
  }
);

export default createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(submitFilesQuery.pending, (state) => ({
      ...state,
      isLoading: true,
      isError: false,
    }));
    builder.addCase(submitFilesQuery.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      parsedText: action.payload.parsedText,
      sources: action.payload.sources,
    }));
    builder.addCase(submitFilesQuery.rejected, (state) => ({
      ...state,
      isLoading: false,
      isError: true,
    }));
  },
});

// const sampleResponseData = {
//     data: {
//       result:
//         "Imagination environment is a concept presented in the paper 'Dream to Control: Learning Behaviors by Latent Imagination' by Hafner et al. [1]. It refers to a Markov decision process that is fully observed in a compact latent space of a learned world model. Imagined trajectories in this environment start at true model states st of observation sequences drawn from the agent’s past experience, and they follow predictions of the transition model sτ ∼ q(sτ | sτ −1, aτ −1), reward model rτ ∼ q(rτ | sτ ), and a policy aτ ∼ q(aτ | sτ ). The objective is to maximize expected imagined rewards Eq (cid:80)∞ τ=t γτ−trτ.",
//       sources: {
//         "1": {
//           content:
//             "Published as a conference paper at ICLR 2020  DREAM TO CONTROL: LEARNING BEHAVIORS BY LATENT IMAGINATION  Danijar Hafner ∗ University of Toronto Google Brain  Timothy Lillicrap DeepMind  Jimmy Ba University of Toronto  Mohammad Norouzi Google Brain  Abstract  Learned world models summarize an agent’s experience to facilitate learning complex behaviors. While learning world models from high-dimensional sensory inputs is becoming feasible through deep learning, there are many potential ways for deriving behaviors from them. We present Dreamer, a reinforcement learning agent that solves long-horizon tasks from images purely by latent imagination. We efﬁciently learn behaviors by propagating analytic gradients of learned state values back through trajectories imagined in the compact state space of a learned world model. On 20 challenging visual control tasks, Dreamer exceeds existing approaches in data-efﬁciency, computation time, and ﬁnal performance.  0 2 0 2  r a  M 7 1  1  INTRODUCTION",
//           filename:
//             "Hafner_et_al._-_2020_-_Dream_to_Control_Learning_Behaviors_by_Latent_Ima.pdf",
//         },
//       },
//     },
//   };
