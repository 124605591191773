import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {} from "@azure/msal-react";
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";

import { AppState } from ".";
import { loginRequest, msalConfig } from "../config/authConfig";

const name = "auth";
const msalInstance = new PublicClientApplication(msalConfig);

export interface AuthState {
  isAuthenticated: boolean;
  activeAccount?: AccountInfo;
  displayName: string;
  accessToken?: string;
  accessTokenExpiration?: number | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  displayName: "",
};

export const getFreshAccessToken = createAsyncThunk<
  any,
  void,
  { state: AppState }
>(`${name}/getFreshAccessToken`, async (_, thunkApi) => {
  const state: AppState = thunkApi.getState();
  if (!state.auth.isAuthenticated) {
    console.log("Cannot get access token, user not authenticated.");
    return {};
  }

  if (
    !state.auth.accessTokenExpiration ||
    state.auth.accessTokenExpiration < new Date().getTime() + 60000
  ) {
    console.log("Refreshing access token.");
    const response = await msalInstance.acquireTokenSilent(loginRequest);
    return {
      accessToken: response.accessToken,
      expiration: response.expiresOn?.getTime(),
    };
  }
  return {
    accessToken: state.auth.accessToken,
    expiration: state.auth.accessTokenExpiration,
  };
});

export const handleRedirectCallback = createAsyncThunk<
  any,
  void,
  { state: AppState }
>(`${name}/handleRedirectCallback`, async (_, thunkApi) => {
  console.log("Handling login redirect.");
  const authenticationResult = await msalInstance.handleRedirectPromise();
  window.location.hash = "";
  thunkApi.dispatch(auth.actions.refreshAccount());
  thunkApi.dispatch(getFreshAccessToken());
  return {
    accessToken: authenticationResult?.accessToken,
    expiration: authenticationResult?.expiresOn,
  };
});

export const login = createAsyncThunk(`${name}/login`, async (_, thunkApi) => {
  console.log("Login redirect");
  msalInstance.loginRedirect(loginRequest);
});

export const logout = createAsyncThunk(
  `${name}/logout`,
  async (_, thunkApi) => {
    await msalInstance.logout();
  }
);

export const initialize = createAsyncThunk<any, void, { state: AppState }>(
  `${name}/initialize`,
  (_, thunkApi) => {
    thunkApi.dispatch(auth.actions.refreshAccount());
    thunkApi.dispatch(getFreshAccessToken());
  }
);

const auth = createSlice({
  name,
  initialState,
  reducers: {
    refreshAccount(state) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const account = accounts[0];
        msalInstance.setActiveAccount(account);
        state.displayName = account.username;
        state.isAuthenticated = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFreshAccessToken.fulfilled, (state, action) => ({
      ...state,
      accessToken: action.payload.accessToken,
      accessTokenExpiration: action.payload.expiration,
    }));
  },
});

export default auth;
