import {
  Configuration,
  LogLevel,
  RedirectRequest,
} from "@azure/msal-browser";

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: "b164a809-d80d-47f6-b7e5-29d0afa9aa6c",
    authority: "https://login.microsoftonline.com/8b52ecaa-f734-4a0c-9b2d-ab31beeb4028",
    redirectUri: "/",
    postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["b164a809-d80d-47f6-b7e5-29d0afa9aa6c/User.Read"]
};
