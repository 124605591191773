import React, { useState } from "react";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from "../../ducks";
import {
  submitFilesQuery,
  FilesQueryValues,
  Sources,
  ResponseChunk,
} from "../../ducks/filesQuery";
import { Action } from "../../types";
import PrimeTextareaField from "./components/PrimeTextareaField";
import FileField from "./components/FileField";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./home.css";

const initialValues: FilesQueryValues = {
  prompt: "",
  files: [],
};

const getSourceId = (key: string): string => `source_${key}`;

const Home = () => {
  const dispatch = useDispatch<ThunkDispatch<AppState, null, Action>>();

  const parsedText: ResponseChunk[] = useSelector(
    (state: AppState) => state.filesQuery.parsedText
  );
  const sources: Sources = useSelector(
    (state: AppState) => state.filesQuery.sources
  );
  const isLoading = useSelector(
    (state: AppState) => state.filesQuery.isLoading
  );
  const [highlightedSource, highlightSource] = useState<string | undefined>(
    undefined
  );

  return (
    <div className="flex w-full justify-content-center">
      <div className="flex-column w-6">
        <h1 className="">AI Assistant</h1>
        <p>
          Upload the files that you wish to analyze, add a question and submit.
        </p>
        <Formik
          initialValues={initialValues}
          validate={({ prompt, files }) => {
            const errors = {} as any;

            if (!files || files.length < 1) {
              errors.files = "You need to upload a file.";
            }

            if (!prompt || prompt.length < 3) {
              errors.prompt = "Prompt need to be at least 3 characters long.";
            }
            return errors;
          }}
          onSubmit={(values) => dispatch(submitFilesQuery(values))}
        >
          {({ isValid }) => (
            <Form>
              <h2>File(s):</h2>
              <div>
                <FileField fieldName="files" />
              </div>

              <h2>Question:</h2>
              <div className="flex flex-column gap-4">
                <PrimeTextareaField
                  fieldName="prompt"
                  placeholder="Here comes the question."                  
                />
              </div>
              <div className="flex flex-row justify-content-end mt-3">
                <Button type="submit" disabled={!isValid && !isLoading}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <h2>Response:</h2>
        <div className="border-1 border-round-sm border-double p-2 response">
          {isLoading ? (
            <div className="flex justify-content-center"><ProgressSpinner /></div>
          ) : (
            parsedText.length < 1 
            ? <div className="text-color-secondary text-center">Submit a file with question to get a response.</div>
            :parsedText.map((responseChunk) => (
              <>
                {responseChunk.text}
                {responseChunk.reference && (
                  <a
                    href={`#${getSourceId(responseChunk.reference)}`}
                    onClick={() => highlightSource(responseChunk.reference)}
                  >
                    [{responseChunk.reference}]
                  </a>
                )}
              </>
            ))
          )}
        </div>
        <h2>References:</h2>
        <div className="border-1 border-round-sm border-double p-2 response">
          {isLoading
            ? <div className="text-color-secondary text-center">Loading ...</div>
            : Object.keys(sources).map((sourceKey) => (
                <div id={getSourceId(sourceKey)}>
                  <p
                    className={
                      sourceKey === highlightedSource ? "font-bold" : ""
                    }
                  >
                    {sourceKey} : {sources[sourceKey].filename}
                  </p>
                  <p className="font-italic">{sources[sourceKey].content}</p>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
